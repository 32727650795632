import { useEffect, RefObject, useState, useRef } from "books/act";

type ReturnType = {
  width: number;
  height: number;
  elementRef: RefObject<HTMLDivElement>;
};

const useElementSizeWatcher = (): ReturnType => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const observerRef = useRef<ResizeObserver | null>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    observerRef.current = new ResizeObserver(([entry]) => {
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);
    });
  }, []);

  useEffect(() => {
    elementRef.current &&
      observerRef.current &&
      observerRef.current.observe(elementRef.current);

    return () => {
      observerRef?.current && observerRef.current.disconnect();
    };
  }, [elementRef, observerRef]);

  return {
    width,
    height,
    elementRef,
  };
};

export default useElementSizeWatcher;
