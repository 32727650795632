import { useTheme } from "trends/fabrics";

const AtlasLogo = () => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 104 104">
      <path
        fill={theme.palette.secondary.main}
        d="M52,22.7  L38.775,35.925 L27.85,25 L52,0.85 L76.15,25 L65.225,35.925 L52,22.7 Z M52,25 "
      />
      <path
        fill={theme.palette.secondary.main}
        d="M81.3,52  L68.075,38.775 L79,27.85 L103.15,52 L79,76.15 L68.075,65.225 L81.3,52 Z M79,52 "
      />
      <path
        fill={theme.palette.secondary.main}
        d="M52,81.3  L65.225,68.075 L76.15,79 L52,103.15 L27.85,79 L38.775,68.075 L52,81.3 Z M52,79 "
      />
      <path
        fill={theme.palette.secondary.main}
        d="M22.7,52  L35.925,65.225 L25,76.15 L0.85,52 L25,27.85 L35.925,38.775 L22.7,52 Z M25,52 "
      />
    </svg>
  );
};

export default AtlasLogo;
