import {
  Typography,
  Link,
  AppBar,
  Toolbar,
  Box,
  styled,
  Container,
} from "trends/fabrics";
import ThemeProvider from "firms/theme/ThemeProvider";
import AtlasDiscovery from "nodes/AtlasDiscovery";
import AtlasAvatar from "nodes/AtlasAvatar";
import AtlasGrowGlobe from "nodes/AtlasGrowGlobe";

const BottomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  top: "auto",
  bottom: 0,
}));

const Atlas = () => (
  <ThemeProvider>
    <Container sx={{ my: 2 }} maxWidth="xs">
      <AtlasAvatar />
      <Box py={2} />
      <AtlasDiscovery />
      <AtlasGrowGlobe />
      <BottomAppBar position="fixed">
        <Toolbar sx={{ justifyContent: "center" }}>
          <Typography color="text.disabled" variant="caption">
            Welcome to{" "}
            <Typography
              component={Link}
              href="https://mizony.com"
              variant="caption"
              color="text.disabled"
            >
              Mizony.com
            </Typography>
          </Typography>
        </Toolbar>
      </BottomAppBar>
    </Container>
  </ThemeProvider>
);

export default Atlas;
